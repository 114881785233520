@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/kit/core/style'; // Clean UI KIT styles
@import 'components/cleanui/styles/style'; // Clean UI styles

.fullscreen-enabled {
  background: #fff;
  padding: 2rem;
}
